<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="bannerImg" alt="" class="bigPicture" />
      <div class="box">
        <div class="tipOne">{{ $t("departmentSettings.DS_tipOne") }}</div>
        <div
          v-for="(item, index) in $t('departmentSettings.DS_modular')"
          :key="index"
          class="modularItem"
          @click="modularItem(item.type)"
        >
          <div class="icon">
            <img :src="item.img" alt="" />
          </div>
          <div class="cont">
            <div>
              <div class="title">{{ item.title }}</div>
              <div class="titleTwo">{{ item.titleTwo }}</div>
            </div>
            <div class="see">
              <div>{{ item.see }}</div>
              <img src="@/assets/img/checkbai.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：技术研发-部门设置
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-18 15:29
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "部门设置",
          title_en: "Department settings",
          path: "",
        },
      ],
      bannerImg: "",
    };
  },
  mounted() {
    this.getBigPicture();
  },
  methods: {
    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-25
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgTwo",
        data: {
          type: 7, // 类型:1=人才培养,2=研发服务,3=专利服务,4=研发条件,5=科研成果,6=研发方向,7=部门设置
        },
      }).then((res) => {
        console.log("大图", res);
        this.bannerImg = res.img;
      });
    },

    /**
     * 跳转
     * 刘嘉鑫
     * 2022-9-27
     */
    modularItem(type) {
      if (type == 1) {
        this.$router.push({
          path: "designProductDepartment",
        });
      }
      if (type == 2) {
        this.$router.push({
          path: "lithographySection",
        });
      }
      if (type == 3) {
        this.$router.push({
          path: "processPlatformDepartment",
        });
      }
      if (type == 4) {
        this.$router.push({
          path: "processIntegrationDepartment",
        });
      }
      if (type == 5) {
        this.$router.push({
          path: "materialPackaging",
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px 60px;
  background: #f6f6f6;
}

.bigPicture {
  height: 400px;
  width: 100%;
  margin-bottom: 80px;
}

.box {
  padding: 0 50px;
}

.tipOne {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #1a2a60;
  margin-bottom: 60px;
}

.modularItem {
  display: flex;
  align-items: center;
  height: 240px;
  margin-bottom: 60px;
  cursor: pointer;

  .icon {
    width: 30%;
    padding: 50px 0;
    background: #ffffff;
    > img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      margin: auto auto;
    }
  }
  .cont {
    background: #1a2a60;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    height: 100%;
    padding: 0 100px;

    .title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .titleTwo {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      opacity: 0.6;
    }

    .see {
      display: flex;
      align-items: center;
      > div {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-right: 10px;
      }

      > img {
        width: 20px;
        height: 12px;
      }
    }
  }
}

.modularItem:hover {
  .see {
    > img {
      animation: checkImg 0.7s linear infinite;
      position: relative;
    }
  }
}
</style>